@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.JobSaveSkillsForm {
  @extend .double-spaced-rows, .widget-block;
  .label {
    font-weight: bold;
  }
  .invite-msg-col {
    text-align: center;
    font-size: 1.3em;
  }

  .skills-col-container {
    @extend .simple-spaced-rows;
  }
}
