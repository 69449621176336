@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_colors";

.JobsBlock {

  @extend .simple-spaced-rows;

  .search-block-row {
    text-align: center;
  }

  .button-col {
    @extend .col-md-auto;
  }

  .list-header {
    // Center the tabs and icons vertically
    align-items: center;
  }

  .first-icon {
    // Put the icons to the right of the tabs
    margin-left: auto;
  }

  .sort-toggle {
    cursor: pointer;
  }
}
