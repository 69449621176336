@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobSaveSalaryForm {
  .label-col {
    font-weight: bold;
    text-align: center;
  }

  .label-div {
    padding-top: 0.6em;
  }

  .label {
    margin-right: 0.5em;
    display: inline-block;
  }

  .input-row-container {
    justify-content: center;
  }
}
