@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.JobSaveTitleForm {
  .job-title {
    text-align: center;
    font-size: 1.3em;
  }

  .job-title-container {
    @extend .double-spaced-rows, .widget-block;
  }
}
