@import "../../../scss/_bootstrap-configuration";

.WysiwygCounterDisplay{

  .danger {
    color: $danger;
    font-size: 1.5em;
  }
  .classic {
    color: $maw-blue;
  }

  .character-word {
    margin-left: 0.5em;
  }

}


