@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.JobDashboardSummaryBlock {
  @extend .double-spaced-rows;
  .button-col {
    @extend .col-auto;
  }

  .link-col {
    margin-top: 0.5em;
    text-align: right;
    @extend .text-lg-end;
  }

  .job-detail {
    @extend .col-12, .col-sm-8, .col-md-6;
  }

  .draft-mention {
    @extend .job-status;
  }

  .pending-mention {
    @extend .job-status;
  }

  .closed-mention {
    @extend .job-status;
  }

  .published-mention {
    @extend .job-status;
  }

  .job-information-col {
    @extend .simple-spaced-rows;
  }
}
