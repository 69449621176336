@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.JobSaveLocationForm {
  @extend .double-spaced-rows, .widget-block;

  .location-invite-msg-col {
    text-align: center;
    font-size: 1.3em;
  }

  .input-col-container {
    @extend .simple-spaced-rows;
  }
}
