@import "../../../scss/_bootstrap-configuration";

.InputTextEditComponent {
  .btn-danger {
    color: $maw-white;

    &:hover,
    &:focus,
    &:active {
      color: $maw-white;
    }
  }

  .error {
    color: $danger;
  }
  .validated-button-col{
    text-align: right;
}
}
