@import "../../../scss/_colors";

.ApplicationByJobCount {

  .LoadingError {
    text-align: center;
    color: $error-red;
  }

  .notification {
    padding: 0.3em;
    display: inline-block;
    margin-bottom: 0.5em;
  }

  .notification-on {
    @extend .notification;
    top: -0.3em;
    background: $error-red;
    border-radius: 50%;
  }

  .notification-off {
    @extend .notification;
  }
}
