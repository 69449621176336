@import "../../scss/_bootstrap-configuration";
@import "../../scss/_layout";
@import "../../scss/_index";

.connection-mode-div {
  margin-top: 5em;
  .sign-in-action {
    width: 30%;
  }
  .sign-in-action-recruiter {
    @extend .btn-secondary;
    width: 30%;
  }
  // Center items horizontally
  text-align: center;
  @extend .big-spaced-rows;
  .row {
    justify-content: center;
  }
  .sign-in-action-col-container {
    @extend .simple-spaced-rows;
  }
}
