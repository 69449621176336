@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.JobCloseForm {
  .title-col {
    font-weight: bold;
  }
  .checkbox-col {
    @extend .col-md-auto;
  }

  .form-label {
    font-size: 0.75em;
  }

}
