@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../scss/_layout";
@import "../../scss/_index";

.SignInPage {
  // Center items horizontally
  @extend .simple-spaced-rows;
  text-align: center;
  .row {
    justify-content: center;
  }
}
.SignInBlock {
  @extend .simple-spaced-rows;

  .sign-in-form-col{
    @extend .col-12, .col-md-7, .col-lg-6;
  }
}

.sign-in-form-div{
  @extend .widget-block;
}