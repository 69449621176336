@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.PaymentBlock {
  .button-col {
    @extend .col-auto;
  }

  .content-col {
    @extend .col-12, .col-sm-9;
  }

  .nav-items {
    flex-direction: column;
    border: 1px solid $maw-blue;
  }
}
