@import "../../../scss/bootstrap-configuration";

.ValidatedInputSelect {
  select.form-control {
    // Put back the dropdown arrow
    appearance: auto;
  }

  // The blank option is grayed out when the dropdown is open and when it is selected (that is why we have to set the
  // color of the whole select box when the blank option is selected).
  &.blank-option-selected select, option.blank-option {
    color: $input-placeholder-color;
  }

  // Options other than the blank option are not grayed out (counteract the whole grayed out select box when the
  // currently selected option is the blank option)
  option:not(.blank-option) {
    color: $maw-blue;
  }
}
