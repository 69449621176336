@import "../../../scss/_colors";

.customButton {
  width: 10em;
  height: 2em;
  background-color: $maw-blue;
  color: $maw-white;
  border: 0.1em solid $maw-blue;
  margin-left: 1em;
  margin-bottom: 0.2em;
  border-radius: 1em;
  cursor: pointer;
}

.activeButton {
  background-color: $maw-green;
  border: 0.1em solid $maw-green;
}
