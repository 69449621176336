@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../scss/_index";

.ApplicationPreview {
  .name-row {
    align-items: center;
  }

  .action-icon {
    @extend .col-auto;
  }

  .action-col {
    font-weight: bold;
  }

  .link {
  }

  .Checkbox-col {
    @extend .action-icon;
    padding-right: 0;
  }

  .new-badge {
    color: $maw-green !important;
    background-color: $maw-white !important;
    margin-left: 1em;
  }

  .new-badge-col {
    @extend .col-2;
  }

  .checkbox:checked {
    background-color: $maw-green !important;
  }

  .downloadContainer {
    @extend .col-1;
  }
  .personIconContainer {
    @extend .col-auto;
  }
  .referrerLabelContainer {
    @extend .col-auto;
  }
  .noteParagraph {
    font-size: 0.8em;
    font-style: italic;
  }
  .ApplicationUpdateModal {
    .modal-dialog {
      // Make this modal larger
      @extend .modal-lg;
    }
  }
}
